var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "features-block" },
    [
      _c("content-wrapper-block", [
        _c(
          "div",
          { staticClass: "features-block__header feature-header" },
          [
            _c("div", { staticClass: "feature-header__tabs" }, [
              _c(
                "svg",
                {
                  staticClass: "feature-header__arrow-desktop",
                  attrs: {
                    width: "23",
                    height: "179",
                    viewBox: "0 0 23 159",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M14 152L17 155L14 158",
                      stroke: "#E14313",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M19 152L22 155L19 158",
                      stroke: "#E14313",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                  _c("path", {
                    attrs: { d: "M1 0V155H11", stroke: "#E14313" },
                  }),
                ]
              ),
              _c(
                "svg",
                {
                  staticClass: "feature-header__arrow-mobile",
                  attrs: {
                    width: "24",
                    height: "30",
                    viewBox: "0 0 24 60",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M15 33L18 36L15 39",
                      stroke: "#E14313",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M20 33L23 36L20 39",
                      stroke: "#E14313",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                  _c("path", { attrs: { d: "M1 0V36H11", stroke: "#E14313" } }),
                ]
              ),
              _c("div", { staticClass: "feature-header__hint" }, [
                _vm._v("Виды отделки"),
              ]),
              _c("div", { staticClass: "feature-header__tab-items" }, [
                false
                  ? _c("div", { staticClass: "feature-header__tab-wrapper" }, [
                      _c(
                        "span",
                        {
                          class: {
                            "feature-header__tab": true,
                            "feature-header__tab--active": _vm.activeTab === 1,
                          },
                          on: {
                            click: function ($event) {
                              _vm.activeTab = 1
                            },
                          },
                        },
                        [_vm._v(" Business")]
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "feature-header__tab-wrapper" }, [
                  _c(
                    "span",
                    {
                      class: {
                        "feature-header__tab": true,
                        "feature-header__tab--active": _vm.activeTab === 2,
                      },
                      on: {
                        click: function ($event) {
                          _vm.activeTab = 2
                        },
                      },
                    },
                    [_vm._v(" Optima")]
                  ),
                ]),
              ]),
            ]),
            _c("page-title", [_vm._v("Виды отделки")]),
          ],
          1
        ),
        _c("div", { staticClass: "features-block__slider feature-slider" }, [
          _c(
            "div",
            { staticClass: "feature-slider__information slider-information" },
            [
              _c("div", { staticClass: "slider-information__type" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.activeTab === 1 ? "Business" : "Optima") +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "slider-information__cards" },
                _vm._l(_vm.currentStruct.features, function (feature, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "slider-information__card" },
                    [
                      _c(
                        "div",
                        { staticClass: "slider-information__card-name" },
                        [_vm._v(" " + _vm._s(feature.name) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "slider-information__card-description" },
                        [_vm._v(" " + _vm._s(feature.description) + " ")]
                      ),
                    ]
                  )
                }),
                0
              ),
              _c(
                "ui-button",
                {
                  staticClass: "slider-information__button",
                  on: {
                    click: function ($event) {
                      return _vm.showPresentationDialog(
                        "Получите презентацию по отделке на WhatsApp",
                        "Отправить",
                        true,
                        "features-block__presentation"
                      )
                    },
                  },
                },
                [_vm._v(" Получите презентацию по отделке ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "feature-slider__images feature-images" },
            [
              _c(
                "swiper",
                {
                  ref: "swiper",
                  staticClass: "feature-images__swiper",
                  attrs: {
                    options: {
                      spaceBetween: 20,
                    },
                  },
                  on: { "slide-change": _vm.slideChange },
                },
                _vm._l(_vm.currentStruct.images, function (slide) {
                  return _c(
                    "swiper-slide",
                    { key: slide.image, staticClass: "feature-images__slide" },
                    [
                      _c("img", {
                        staticClass: "feature-images__slide-image",
                        attrs: { src: slide.image },
                      }),
                    ]
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "feature-images__footer images-footer" },
                [
                  _c("div", { staticClass: "images-footer__info" }, [
                    _vm.currentSlide
                      ? _c(
                          "div",
                          { staticClass: "images-footer__info-title" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.currentSlide.description) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "images-footer__navigation" },
                    [
                      _c("slider-navigation-arrows", {
                        attrs: {
                          "active-next": _vm.svipeNext,
                          "active-prev": _vm.svipePrev,
                          "disable-prev": _vm.disableButtonPrev,
                          "disable-next": _vm.disablButtonNext,
                        },
                        on: { next: _vm.goToNext, prev: _vm.goToPrev },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }