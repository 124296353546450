<template>
  <div class="features-block">
    <content-wrapper-block>
      <div class="features-block__header feature-header">
        <div class="feature-header__tabs">
          <svg
            class="feature-header__arrow-desktop"
            width="23"
            height="179"
            viewBox="0 0 23 159"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 152L17 155L14 158"
              stroke="#E14313"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19 152L22 155L19 158"
              stroke="#E14313"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M1 0V155H11" stroke="#E14313" />
          </svg>
          <svg
            class="feature-header__arrow-mobile"
            width="24"
            height="30"
            viewBox="0 0 24 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 33L18 36L15 39"
              stroke="#E14313"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20 33L23 36L20 39"
              stroke="#E14313"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M1 0V36H11" stroke="#E14313" />
          </svg>
          <div class="feature-header__hint">Виды отделки</div>
          <div class="feature-header__tab-items">
            <div v-if="false" class="feature-header__tab-wrapper">
              <span
                :class="{
                  'feature-header__tab': true,
                  'feature-header__tab--active': activeTab === 1,
                }"
                @click="activeTab = 1"
              >
                Business</span
              >
            </div>
            <div class="feature-header__tab-wrapper">
              <span
                :class="{
                  'feature-header__tab': true,
                  'feature-header__tab--active': activeTab === 2,
                }"
                @click="activeTab = 2"
              >
                Optima</span
              >
            </div>
          </div>
        </div>
        <page-title>Виды отделки</page-title>
      </div>
      <div class="features-block__slider feature-slider">
        <div class="feature-slider__information slider-information">
          <div class="slider-information__type">
            {{ activeTab === 1 ? "Business" : "Optima" }}
          </div>
          <div class="slider-information__cards">
            <div
              v-for="(feature, index) in currentStruct.features"
              :key="index"
              class="slider-information__card"
            >
              <div class="slider-information__card-name">
                {{ feature.name }}
              </div>
              <div class="slider-information__card-description">
                {{ feature.description }}
              </div>
            </div>
          </div>
          <ui-button
            class="slider-information__button"
            @click="
              showPresentationDialog(
                'Получите презентацию по отделке на WhatsApp',
                'Отправить',
                true,
                'features-block__presentation'
              )
            "
          >
            Получите презентацию по отделке
          </ui-button>
        </div>
        <div class="feature-slider__images feature-images">
          <swiper
            ref="swiper"
            class="feature-images__swiper"
            :options="{
              spaceBetween: 20,
            }"
            @slide-change="slideChange"
          >
            <swiper-slide
              v-for="slide in currentStruct.images"
              :key="slide.image"
              class="feature-images__slide"
            >
              <img class="feature-images__slide-image" :src="slide.image" />
            </swiper-slide>
          </swiper>

          <div class="feature-images__footer images-footer">
            <div class="images-footer__info">
              <div v-if="currentSlide" class="images-footer__info-title">
                {{ currentSlide.description }}
              </div>
            </div>
            <div class="images-footer__navigation">
              <slider-navigation-arrows
                :active-next="svipeNext"
                :active-prev="svipePrev"
                :disable-prev="disableButtonPrev"
                :disable-next="disablButtonNext"
                @next="goToNext"
                @prev="goToPrev"
              />
            </div>
          </div>
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import UiButton from "@/components/ui/UIButton";
import SliderNavigationArrows from "@/components/ui/SliderNavigationArrows";
import { mapState } from "vuex";

export default {
  name: "FeaturesBlock",
  components: {
    SliderNavigationArrows,
    UiButton,
    PageTitle,
    ContentWrapperBlock,
  },
  props: {
    /**
     * business and optima
     * : [
     *  {
     *    features: [
     *      {
     *        name: '',
     *        description: ''
     *      }
     *    ],
     *    images: [
     *      image: '',
     *      name: '',
     *      description: ''
     *    ]
     *  }
     * ]
     */
    business: {
      type: Object,
      default: () => {
        return null;
      },
    },
    optima: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data: () => ({
    activeTab: 2,
    activeSlide: 1,
    svipeNext: false,
    svipePrev: false,
    disableButtonPrev: true,
    disablButtonNext: false,
  }),
  computed: {
    ...mapState({
      featuresState: (state) => state.features,
    }),
    businessArray() {
      return this.business !== null
        ? this.business
        : this.featuresState.business;
    },
    optimaArray() {
      return this.optima !== null ? this.optima : this.featuresState.optima;
    },
    currentStruct() {
      if (this.activeTab === 1) {
        return this.businessArray;
      }
      return this.optimaArray;
    },
    currentSlide() {
      if (this.currentStruct === undefined) {
        return null;
      }

      return this.currentStruct.images[this.activeSlide - 1];
    },
  },
  watch: {
    activeTab() {
      this.$refs.swiper.$swiper.slideTo(0);
      this.$nextTick(() => {
        // this.draw = true
        if (this.$refs.swiper) {
          this.$refs.swiper.$swiper.slideTo(0);
        }
      });
    },
  },
  created() {
    this.$nextTick(() => {
      this.$refs.swiper.$swiper.updateSlides();
      this.$nextTick(() => {
        this.$refs.swiper.$swiper.on("slideNextTransitionStart", () => {
          this.disableButtonPrev = false;
        });
        this.$refs.swiper.$swiper.on("slidePrevTransitionStart", () => {
          this.disablButtonNext = false;
        });
        this.$refs.swiper.$swiper.on("slideNextTransitionStart", () => {
          this.svipeNext = true;
          this.svipePrev = false;
        });
        this.$refs.swiper.$swiper.on("slidePrevTransitionStart", () => {
          this.svipeNext = false;
          this.svipePrev = true;
        });
        this.$refs.swiper.$swiper.on("reachEnd", () => {
          this.disablButtonNext = true;
          this.disableButtonPrev = false;
        });
        this.$refs.swiper.$swiper.on("reachBeginning", () => {
          this.disablButtonNext = false;
          this.disableButtonPrev = true;
        });
      });
    });
  },
  methods: {
    goToNext() {
      this.$refs.swiper.$swiper.updateSlides();
      this.$refs.swiper.$swiper.slideNext();
    },
    goToPrev() {
      this.$refs.swiper.$swiper.updateSlides();
      this.$refs.swiper.$swiper.slidePrev();
    },
    slideChange() {
      this.activeSlide = this.$refs.swiper.$swiper.activeIndex + 1;
    },
  },
};
</script>

<style scoped lang="scss">
.features-block {
  background-color: #2e2a2b;
  color: #ffffff;
  padding-top: 0;
  padding-bottom: 25px;

  @media screen and (min-width: $screen-md) {
    padding-top: 55px;
  }

  &__header,
  .feature-header {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding-bottom: 30px;

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      margin-bottom: 45px;
    }

    &:after {
      @media screen and (min-width: $screen-md) {
        display: block;
        content: " ";
        position: absolute;
        height: 1px;
        background: #fff;
        bottom: 0;
        left: 20px;
        right: 20px;
        width: 98%;
      }
    }

    &__tabs {
      display: flex;
      flex-direction: column;
      width: 150px;
      position: relative;
    }

    &__arrow-mobile {
      position: absolute;
      top: 25px;
      display: block;
      left: 1px;

      @media screen and (min-width: $screen-md) {
        display: none;
      }
    }

    &__arrow-desktop {
      position: absolute;
      top: 30px;
      display: none;
      @media screen and (min-width: $screen-md) {
        display: block;
      }
    }

    &__hint {
      font-family: $font-family-rubik;
      font-size: 12px;
      opacity: 0.5;
      font-weight: 400;
      margin-bottom: 15px;
    }

    &__tab {
      font-family: $font-family-bebas;
      font-size: 22px;
      font-weight: 700;
      opacity: 0.5;
      transition: all 300ms ease-out;
      cursor: pointer;
      margin-left: 20px;
      border-bottom: 1px solid #ffffff;

      &:hover {
        border-color: #e14313;
        color: #e14313;
      }

      &--active {
        border-color: #e14313;
        opacity: 1;
        pointer-events: none;
      }

      &-wrapper {
        margin-bottom: 10px;

        &:first-child {
          margin-left: 20px;
          @media screen and (min-width: $screen-md) {
            margin-left: 0;
          }
        }
      }

      &-items {
        display: flex;

        @media screen and (min-width: $screen-md) {
          flex-direction: column;
        }
      }
    }
  }

  &__slider,
  .feature-slider {
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
    }

    &__slide {
      flex: 1;
    }

    &__information,
    .slider-information {
      padding-top: 30px;

      @media screen and (min-width: $screen-md) {
        padding-right: 30px;
        padding-top: 0;
      }

      &__button {
        margin-top: 15px;
        width: 100%;

        @media screen and (min-width: $screen-mini) {
          width: auto;
        }

        @media screen and (min-width: $screen-md) {
          width: 100%;
        }
      }

      &__type {
        font-family: $font-family-bebas;
        font-size: 36px;
        margin-bottom: 36px;
        padding-left: 35px;
        display: none;

        @media screen and (min-width: $screen-md) {
          display: block;
        }
      }

      &__cards {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $screen-sm) {
          flex-direction: row;
        }

        @media screen and (min-width: $screen-md) {
          flex-direction: column;
        }
      }

      &__card {
        font-family: $font-family-rubik;
        box-sizing: border-box;
        display: block;
        flex: 1;

        @media screen and (min-width: $screen-sm) {
          padding-right: 30px;

          &:last-child {
            padding-right: 0;
          }
        }

        @media screen and (min-width: $screen-md) {
          width: 250px;
          padding-right: 0;
        }

        &-name {
          font-size: 14px;
          font-weight: 500;
          padding-bottom: 9px;
        }

        &-description {
          font-size: 12px;
          opacity: 0.8;
          padding-bottom: 20px;
          font-weight: 300;
        }
      }
    }

    &__images,
    .feature-images {
      overflow: hidden;
      flex: 1;

      &__swiper {
        width: 100%;
      }

      &__slide {
        cursor: grab;
        &-image {
          max-height: inherit;
          object-fit: contain;
        }
      }

      &__footer,
      .images-footer {
        display: none;
        margin-top: 30px;

        @media screen and (min-width: $screen-md) {
          display: flex;
        }

        &__info {
          font-family: $font-family-rubik;
          width: 100%;
          display: flex;
          align-items: center;

          &-title {
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 9px;
          }
          &-description {
            font-size: 12px;
            font-weight: 300;
            opacity: 0.8;
          }
        }
      }
    }
  }
  .swiper-slide {
    width: max-content;
    max-height: 170px;

    @media screen and (min-width: $screen-xs) {
      max-height: 250px;
    }

    @media screen and (min-width: $screen-sm) {
      max-height: 450px;
    }
  }
}
</style>
